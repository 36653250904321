export const eventsConfig = [
    {
        id: 117,
        eventDay: '22',
        eventDayNumber: 'Вс',
        eventInfoDate: '22 сентября',
        eventInfoTitle: 'День села Былово',
        eventDescription: '22 сентября Приглашаем на день села Былово, вас ждут различные активности, угощения, мастер-классы, гильдия мастеров и конечно концертная программа',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/DenSela-02.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/DenSela-01.jpg'),
    },
    {
        id: 117,
        eventDay: '21',
        eventDayNumber: 'СБ',
        eventInfoDate: '21 сентября',
        eventInfoTitle: 'РЕДУТ времён Отечественной войны 1812 года',
        eventDescription: '21 сентября Приглашаем в Мемориальный визит-центр РеЕДУТ и погрузится в атмосферу XIX века',
        eventCost: 'РЕДУТ времён Отечественной войны 1812 года',
        eventImgSrc: require('../../assets/images/eventsImages/Redytt-02.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Redytt-01.jpg'),
    },
    {
        id: 116,
        eventDay: '03',
        eventDayNumber: 'ПН',
        eventInfoDate: '03-22 сентября',
        eventInfoTitle: 'Расписание мероприятий ДК «Звездный»',
        eventDescription: 'С 03 по 22 сентября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/oakp-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/oakp-02.jpg'),
    },
    {
        id: 116,
        eventDay: '03',
        eventDayNumber: 'ПН',
        eventInfoDate: '03-22 сентября',
        eventInfoTitle: 'Расписание мероприятий ДК «Юбилейный»',
        eventDescription: 'С 03 по 22 сентября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/oab-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/oab-02.jpg'),
    },
    {
        id: 116,
        eventDay: '24',
        eventDayNumber: 'СБ',
        eventInfoDate: '24 августа',
        eventInfoTitle: 'Игровая программа',
        eventDescription: '23 августа в 12-00 приглашаем детей и их родителей на игровую программу "Первый раз в первый класс" в селе Былово, будет весело и интересно!',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/1kl-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/1kl-02.jpg'),
    },
    {
        id: 116,
        eventDay: '22',
        eventDayNumber: 'ЧТ',
        eventInfoDate: '22 августа',
        eventInfoTitle: 'Концертная программа',
        eventDescription: '22 августа в 18-00 состоиться концертная программа в селе Былово ко дню флага, ждем всех жителей и гостей',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/flaggg-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/flaggg-02.jpg'),
    },
    {
        id: 116,
        eventDay: '16',
        eventDayNumber: 'ПТ',
        eventInfoDate: '16 августа',
        eventInfoTitle: 'День варенья',
        eventDescription: '16 августа в 16-00,д. Раево, ЖК «Остров Эрин», ул. Джонатана Свифта.Берите с собой хорошее настроение и присоединяйтесь к празднику всей семьей!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/varenie-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/varenie-02.jpg'),
    },
    {
        id: 116,
        eventDay: '10',
        eventDayNumber: 'СБ',
        eventInfoDate: '10 августа',
        eventInfoTitle: 'КИНОЗАВР',
        eventDescription: '10 августа в 16-00 в Красной Пахре состоится фестиваль короткометражного кино "Кинозавр- 2024". И,  хотя фестиваль молодежный, вход открыт для всех! ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/kinizavr-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/kinizavr-02.jpg'),
    },
    {
        id: 116,
        eventDay: '08',
        eventDayNumber: 'ПН',
        eventInfoDate: '08 июля',
        eventInfoTitle: 'Всё начинается с любви',
        eventDescription: '08 июня состоится онлайн-фестиваль посвящённый Дню семьи,любви и верности, следите за нашими соц сетями!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/DLCV_2-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/DLCV_2-02.jpg'),
    },
    {
        id: 116,
        eventDay: '30',
        eventDayNumber: 'ВС',
        eventInfoDate: '30 июня',
        eventInfoTitle: 'Троицкие гуляния',
        eventDescription: '30 июня в 12-00 приглашаем всех желающих посетить гжегодный праздник "Троицие гуляния" в парке Победы на свещем воздухе',
        eventCost: 'Парк Победы',
        eventImgSrc: require('../../assets/images/eventsImages/troica-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/troica-02.jpg'),
    },
    {
        id: 116,
        eventDay: '22',
        eventDayNumber: 'СБ',
        eventInfoDate: '22 июня',
        eventInfoTitle: 'Свеча памяти',
        eventDescription: 'Памятная акция, посвящённая началу Великой Отечественной войны 1491-1945 гг.Начало шемствия от с. Былово до Красной Пахры "Парка победы" ',
        eventCost: '"село Былово у обелиска"',
        eventImgSrc: require('../../assets/images/eventsImages/Sve4a-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Sve4a-02.jpg'),
    },
    {
        id: 116,
        eventDay: '14',
        eventDayNumber: 'ПТ',
        eventInfoDate: '14 июня',
        eventInfoTitle: 'КВИЗ',
        eventDescription: 'Друзья, мы ждём вас 14 июня в 12-00 в доме культуры «Юбилейный» на КВИЗ на тему "Вокуг света"',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/tolik-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/tolik-02.jpg'),
    },
    {
        id: 116,
        eventDay: '09',
        eventDayNumber: 'ЧТ',
        eventInfoDate: '9 июня',
        eventInfoTitle: 'Концертная программа',
        eventDescription: 'Друзья, мы ждём вас 09 июня в 18-00 в доме культуры «Звёздный» на Праздничном концерте ко Дню России',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/flagi-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/flagi-02.jpg'),
    },
    {
        id: 116,
        eventDay: '06',
        eventDayNumber: 'ЧТ',
        eventInfoDate: '6 июня',
        eventInfoTitle: 'Афиша мероприятий',
        eventDescription: 'Мы рады представить все мероприятия запланированные на июнь в ДК "Звёздный" с 06 июня по 28 июня',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/iun-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/iun-02.jpg'),
    },
    {
        id: 116,
        eventDay: '01',
        eventDayNumber: 'СБ',
        eventInfoDate: '1 июня',
        eventInfoTitle: 'Игровая программа',
        eventDescription: 'Мы рады пригласить вас на мероприятия, посвящённые Международному дню защиты детей. Они состоятся в Краснопахорском уже завтра, 1 июня',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/detieti-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/detieti-02.jpg'),
    },
    {
        id: 116,
        eventDay: '31',
        eventDayNumber: 'ВС',
        eventInfoDate: '26 мая',
        eventInfoTitle: 'Музыкальный проект',
        eventDescription: '26 мая состоится долгожданный финал музыкального проекта «Голоса Новой Москвы»! Начало в 14:00.Вход свободный. А еще вас ждут специальные гости - звезды российской эстрады!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/GOLOSAaaaa-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/GOLOSAaaaa-02.jpg'),
    },

]